import styled from 'styled-components';

// Define the styled button component
const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #ba68c8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #9c27b0;
  }
`;

// LogoutButton component
const LogoutButton = ({ onClick }: {onClick: any}) => {
  return <Button onClick={onClick}>Logout</Button>;
};

export default LogoutButton;

// import { Title } from "../Components/StyledTitle";
import Layout from "../Layout";
import { Player } from '@lottiefiles/react-lottie-player';
import * as SuccessAnimation from '../Assets/success-animation.json'
import * as FailAnimation from '../Assets/fail-animation.json'
import { Button } from "@mui/material";

export default function TransactionCompleted({approved, cancelled}: {approved: boolean, cancelled?: boolean}) {

  const TransactionStatus = ({title, animationAsset, height, width, margin}:
    {title: string, animationAsset: string| object, height: string, width: string, margin: string}) => {
    
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <h1>{title}</h1>
        <Player
          src={animationAsset}
          className="player"
          autoplay={true}
          keepLastFrame={true}
          style={{ height, width, margin }}
        />
        <Button
            variant="contained"
            onClick={() => window.location.href = "/"}
            style={{ margin: '20px' }}
          >
              Go back
        </Button>
      </div>
    );
  }

  const TransactionApproved = () => {
    return <TransactionStatus 
      title="Transaction Approved"
      animationAsset={SuccessAnimation}
      height='400px'
      width='400px'
      margin= '-100px 0px 0px -100px'
    />
  }
  const TransactionRejected = () => {
    return <TransactionStatus
      title="Transaction Rejected"
      animationAsset={FailAnimation}
      height='150px'
      width='150px'
      margin='50px 50px 50px 50px'
    />
  }
  const TransactionCancelled = () => {
    return <TransactionStatus
      title="Transaction Cancelled"
      animationAsset={FailAnimation}
      height='200px'
      width='200px'
      margin='50px 50px 50px 50px'
    />
  }

  return (
      <Layout disableFloatingFab={false} children={
        <div className="Transfer-money-result">
          {/* show an icon depending if it was approved and a message */}
          {cancelled ? <TransactionCancelled /> :
            approved ?
              <TransactionApproved /> :
              <TransactionRejected />}
        </div>
      } />
  );
}
import { Box, Button, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import { Title } from "../StyledTitle";


const TransferMoneyForm = ({onSend}: {onSend: any}) => {
  const [sender, setSender] = useState({id: '', error: ''});
  const [recipient, setRecipient] = useState({ id: '', error: '' });
  const [amount, setAmount] = useState({ value: 0, error: "" });

  const handleSubmit = async () => {
    // checking if we have the sender id because it will be used as the verifier
    if (sender.id === '') {
      setSender({id: '', error: 'Sender ID is required'});
      return;
    }
    // Simulate an async operation, replace with your actual send logic
    await onSend(sender.id, recipient.id, amount.value);
  };

  return (
    <>
      <Title style={{
        marginLeft: '30px'
      }}>
        Transfer
      </Title>
      <Title style={{
        marginTop: '-30px',
        marginLeft: '130px'
      }}>
        Money
      </Title>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1 },
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '25px',
          width: '300px',
        }}
        noValidate
        autoComplete="off"
      >
        <TextField 
          style={{
            marginBottom: '20px'
          }}
          error={!!sender.error}
          helperText={sender.error}
          id="outlined-basic"
          label="Sender ID"
          variant="outlined"
          value={sender.id}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSender({id: event.target.value, error: ''});
          }}
        />
        <TextField
          style={{
            marginBottom: '20px'
          }}
          id="outlined-basic"
          label="Recipients Number / Alias"
          variant="outlined"
          error={!!recipient.error}
          helperText={recipient.error}
          value={recipient.id}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setRecipient({id: event.target.value, error: ''});
          }}
        />
        <FormControl 
          sx={{ m: 1, marginBottom: '20px' }}
        >
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Amount"
            error={!!amount.error}
            // value={amount.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (isNaN(parseInt(event.target.value)) || Number(event.target.value) < 0) {
                setAmount({ value: 0, error: "Amount must be a positive number" });
                return;
              }
              setAmount({value: parseInt(event.target.value), error: ""});
            }}
          />
          {!!amount.error && (
            <FormHelperText error id="accountId-error">
              {amount.error}
            </FormHelperText>
          )}
        </FormControl>
        <Button 
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSubmit}
        >
          Send
        </Button>
      </Box>
    </>
  );
}

export default TransferMoneyForm;

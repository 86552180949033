import { ReactNode } from "react";
import FloatingFab from "./Components/FloatingFab";
import Navbar from "./Components/Navbar/Navbar";
import NavigationIcon from '@mui/icons-material/Navigation';

const Layout = ({children, disableFloatingFab}: {children: ReactNode, disableFloatingFab?: boolean}) => {

  return (
    <>
    <header className="App-header">
        <Navbar />
      </header>
      {children}
      {!disableFloatingFab && (
        <>
        <div style={{
          position: 'absolute',
          bottom: '70%',
          right: '30%'
        }}>
          <FloatingFab text="Biometrics Autentication" Icon={NavigationIcon} />
        </div><div style={{
          position: 'absolute',
          bottom: '46%',
          right: '10%'
        }}>
            <FloatingFab text="No OTP Hassle" Icon={NavigationIcon} />
          </div><div style={{
            position: 'absolute',
            bottom: '35%',
            right: '45%'
          }}>
            <FloatingFab text="Single Flow" Icon={NavigationIcon} />
          </div>
          </>
      )}
    </>
  )
}

export default Layout;
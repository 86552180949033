import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Pages/App';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import PageNotFound from './Pages/PageNotFound';
import TransactionCompleted from './Pages/TransactionCompleted';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/home" element={<App />}></Route>
      <Route path="/transaction/complete" element={<TransactionCompleted approved={true} />}></Route>
      <Route path="/transaction/failed" element={<TransactionCompleted approved={false} />}></Route>
      <Route path="/transaction/cancelled" element={<TransactionCompleted approved={false} cancelled={true} />}></Route>

      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Fab } from "@mui/material";

const FloatingFab = ({text, Icon}: {text: string, Icon: any}) => {
    return (
        <Fab
          style={{
            display: window.innerWidth < 768 ? 'none' : 'block'
          }}
          variant="extended"
          disableFocusRipple={true}
          disableRipple={true}
          disableTouchRipple={true}
          size="large">
          <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}>
          <Icon sx={{ mr: 1, color: '#9c27b0' }} />
          {text}
          </div>
        </Fab>
    );
}

export default FloatingFab;
import './App.css';
import { useEffect, useMemo, useRef, useState } from 'react';

import TransferMoneyForm from '../Components/TransferMoneyForm/TransferMoneyForm';
import Layout from '../Layout';
import Facenote from '@facenote/webapp-sdk';
import { Backdrop, CircularProgress, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FacenoteEnv, SupportedLanguages } from '@facenote/webapp-sdk/build/types/consts';
import ArrowOutward from '@mui/icons-material/ArrowOutward';

function App() {
  const [iframeOpen, setIframeOpen] = useState(false);
  const iframe: any = useRef(null);
  const [facenoteInitialized, setFacenoteInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  // staging
  const clientId = process.env.REACT_APP_CLIENT_ID || '';
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET || '';
  const deviceId = process.env.REACT_APP_DEVICE_ID || '';
  const enviroment = (process.env.REACT_APP_ENVIROMENT || 'dev') as FacenoteEnv;
  console.log('clientId', process.env);
  console.log('clientSecret', clientSecret);
  console.log('deviceId', deviceId);

  const facenote = useMemo(() => new Facenote(enviroment), [enviroment]);

  const closeIframe = () => {
    setLoading(false);
    setIframeOpen(false);
  };

  const openIframe = () => {
    setLoading(true);
    setIframeOpen(true);
  };

  useEffect(() => {
    async function init() {
      await facenote.initialize(clientId, clientSecret, deviceId, { prefetch: true });
      setFacenoteInitialized(true);
    };
    if(!facenoteInitialized) {
      console.log('Init facenote sdk');
      init();
    }
  }, [facenote, facenoteInitialized]);

  // function used as callback when verify completes
  const onVerify = async (event: any) => {
    closeIframe();
    const result = event.result;
    const userCancelled: boolean = event.errors !== undefined? event.errors.includes('user-cancelled'): false;

    // check if user cancelled the transaction
    if (userCancelled) {
      navigate("../transaction/cancelled", { replace: true });
      return;
    }
    // navigate to transaction completed page
    if (result === true)
      navigate("../transaction/complete", { replace: true });
    else
      navigate("../transaction/failed", { replace: true });
  };

  return (
    <div className="App">
      <Layout children={
        <>
          <div>
            <iframe
              ref={iframe}
              hidden={!iframeOpen}
              style={{
                backgroundColor: '#e7e9eb',
                border: 0,
                position: 'absolute',
                top: '5px',
                left: '70%',
                right: '5px',
                bottom: '40px',
                zIndex: 9999,
              }}
              title="verify-transaction-iframe"
              height="500"
              width="400"
            />
          </div>
          {/* Loading overlay */}
          <Backdrop
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: '#fff',
              backgroundColor: alpha('#000', 0.5), // Customize the overlay color and opacity
            }}
            open={loading}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <h2 style={{
                  marginRight: '20px',
                }}>Please complete the 2FA above</h2>
                <ArrowOutward style={{
                  width: '50px',
                  height: '50px',
                }}/>
              </div>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
          <div className="Transfer-money-form">
            <TransferMoneyForm onSend={async (senderId: string, recipientsId: string, amount: number) => {
              console.log('senderId', senderId);
              console.log('recipientsId', recipientsId);
              console.log('amount', amount);
              if (facenoteInitialized) {
                openIframe();
                await facenote.verify(senderId, undefined, {
                  language: 'en' as SupportedLanguages,
                  iframe: iframe.current
                }, onVerify);
              }
            }}/>
          </div>
        </>
      } />
    </div>
  );
}

export default App;

import { useState } from 'react'
import  styles from './Navbar.module.css';
import LogoutButton from '../LogoutButton/LogoutButton';
import { ReactComponent as Logotype } from '../../Assets/logotype.svg';

function Navbar() {
  // adding the states 
  const [isActive, setIsActive] = useState(false);

  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false)
  }

  const LogoutAction = () => {
    console.log('LogoutAction')
  }

  return (
        <nav className={`${styles.navbar}`}>
          <Logotype style={{
            width: '170px',
            height: '170px',
          }}/>

          <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
            <li onClick={removeActive}>
              <a href='/' className={`${styles.navLink}`}>Home</a>
            </li>
            <li onClick={removeActive}>
              <a href='/' className={`${styles.navLink}`}>Transactions</a>
            </li>
            <li onClick={removeActive}>
              <a href='/' className={`${styles.navLink}`}>Contact</a>
            </li>
          </ul>

          <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`}  onClick={toggleActiveClass}>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
          </div>
          <div className={styles.navButtonSignInOut}>
            <LogoutButton onClick={LogoutAction}/>
          </div>
        </nav>
  );
}


export default Navbar;

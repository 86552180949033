import Layout from "../Layout";

export default function PageNotFound() {

  return (
      <Layout disableFloatingFab={true} children={
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <h2>404</h2>
          <h1>Oops, Page not Found</h1>
        </div>
      } />
  );
}